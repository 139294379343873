<template>
  <div class="list-container">
    <!-- 搜索框 -->
    <van-sticky>
      <div class="query">
        <van-field
          :center="true"
          v-model="keyword"
          placeholder="请输入药品名称"
          @keyup.enter="handleQuery"
        />
      </div>
    </van-sticky>
    <!-- 查询列表 -->
    <van-loading size="24px" vertical color="#3663E0" v-show="loadingShow"
      >加载中...</van-loading
    >
    <div class="query-list" v-show="!loadingShow">
      <van-empty description="暂无此药品" v-if="searchList === null" />
      <van-cell
        v-else
        v-for="drug in searchList"
        :key="drug.id"
        @click="toDetail(drug.id)"
        :title="drug.name"
        is-link
      />
    </div>
    <!-- 关注公众号 -->
    <Attention ref="attention" />
    <!-- 查询次数使用完毕 -->
    <Queries ref="queries" />
  </div>
</template>

<script>
import Attention from '@/components/Attention'
import { searchList } from '@/api/query'

export default {
  data() {
    return {
      keyword: '', // 查询关键字
      searchList: [], // 药品列表
      loadingShow: true,
      userInfo: {}
    }
  },
  components: { Attention },
  methods: {
    // 药品查询
    handleQuery() {
      this.getSearchList(this.keyword)
    },
    // 获取药品列表
    async getSearchList(keyword) {
      try {
        this.loadingShow = true
        const { data } = await searchList({ keyword })
        this.searchList = data
      } catch (error) {
        console.log(error.response)
        if(error.response.data.msg == '请先关注') this.$refs.attention.attentionShow = true
      }
      this.loadingShow = false
    },
    // 药品详情
    toDetail(id) {
      if (this.userInfo.viewnum === 0) {
        return this.$refs.queries.queriesShow = true
      }
      this.$router.push({
        path: '/detail',
        query: {
          id
        }
      })
    }
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getSearchList(this.$route.query.keyword)
  }

}
</script>

<style lang="less" scoped>
.list-container {
  position: relative;
  .query {
    padding: 15px 0;
    display: flex;
    justify-content: center;
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
    .van-cell {
      width: 341px;
      height: 48px;
      background: #f1f2f5;
      border-radius: 8px;
      &::before {
        content: "";
        width: 17px;
        height: 17px;
        background: url("../../assets/images/icon-search.png");
        background-size: 17px 17px;
        margin-right: 10px;
      }
    }
  }
  /deep/ .van-loading {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>